import { convertDistance, getPreciseDistance } from 'geolib';
import React from 'react';

import { Body2, Caption, SubH1 } from '@goodfynd/react-web.lib.typography';
import { StyledFlex } from '@goodfynd/react-web.styles';
import { ButtonLink } from '@goodfynd/react-web.ui.button-link';
import { Link } from '@goodfynd/react-web.ui.link';
import { format, getTimeDisplay } from '@goodfynd/react-web.utils.date-util';

import config from '../../../config';
import strings from '../../../config/strings';
import { local as storageLocal } from '../../../utils/storage-util';
import {
  StyledCardContainer,
  StyledLocation,
  StyledNumber,
  StyledVendorImage,
} from './styles';

import type { ScheduleMapCardProps } from './types';
export function ScheduleMapCard({ item, number = 0 }: ScheduleMapCardProps) {
  const userLocation = storageLocal.get<ItemLocation>(
    config.storageKeys.userLocation
  );

  return (
    <StyledCardContainer>
      <div className="content">
        <StyledFlex
          css={{
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
            gap: '1rem',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <StyledFlex
              css={{
                alignItems: 'flex-start',
                flexWrap: 'nowrap',
                gap: '0.5rem',
              }}
            >
              {number > 0 && <StyledNumber>{number}</StyledNumber>}

              <SubH1 css={{ fontSizeRem: 16, lineHeightRem: 22 }}>
                {!item.scheduleName ||
                item.scheduleName === item.scheduleAddress
                  ? `Event #${number}`
                  : item.scheduleName}
              </SubH1>
            </StyledFlex>

            {!!item.scheduleAddress && (
              <Link
                css={{ fontSize: '0.875rem', lineHeight: '1.5rem' }}
                href={`https://www.google.com/maps/dir/?api=1&destination=${item.scheduleLocation.coordinates.latitude},${item.scheduleLocation.coordinates.longitude}`}
                target="_blank"
              >
                {item.scheduleAddress}
              </Link>
            )}

            {!!item.scheduleDay ? (
              <Body2 css={{ color: '$Neutral09' }}>
                {[
                  format(
                    item.scheduleStart,
                    config.dateFormats.weekDayMonthDay
                  ),
                  `${getTimeDisplay(
                    item.scheduleStart,
                    false
                  ).toLowerCase()} - ${getTimeDisplay(
                    item.scheduleEnd,
                    false
                  ).toLowerCase()}`,
                  userLocation &&
                    item.scheduleLocation &&
                    `${convertDistance(
                      getPreciseDistance(
                        userLocation,
                        item.scheduleLocation.coordinates
                      ),
                      'mi'
                    ).toFixed(1)} mi.`,
                ]
                  .filter(Boolean)
                  .join(config.unicode.centerDotPadded)}
              </Body2>
            ) : (
              <Body2 css={{ color: '$Neutral09' }}>
                {strings.labels.noEvents}
              </Body2>
            )}
          </div>

          {!!item.vendorImage && (
            <StyledVendorImage
              css={{
                backgroundImage: `url(${item.vendorImage})`,
              }}
            />
          )}
        </StyledFlex>

        <StyledLocation>
          <Body2 css={{ color: '$Neutral09' }}>{item.vendorName}</Body2>
          <Caption>
            {item.vendorProductTypes?.map((type) => type.name).join(', ')}
          </Caption>
        </StyledLocation>
      </div>

      <ButtonLink
        align="left"
        css={{
          backgroundColor: '$Neutral00',
          border: '1px solid $GreenBrand',
          marginTop: '0.75rem',
        }}
        href={`${config.env.APP_HOST}/schedules/${item.id}`}
        size="sm"
        target="_blank"
        variant="outlined"
      >
        Order now
      </ButtonLink>
    </StyledCardContainer>
  );
}
