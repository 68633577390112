import { Menu, MenuItem } from '@mui/material';

import { styled } from '../../theme/stitches.config';
import Button from '../Button';

export const StyledFilterMenuToggle = styled(Button, {
  '&.MuiButton-root': {
    backgroundColor: '$White',
    borderColor: '$Neutral05',
    borderRadius: '16px',
    marginTop: 16,
    '+ .MuiButton-root': {
      marginLeft: 8,
    },
    '@lg': {
      marginTop: 0,
    },
  },
});

export const StyledFilterMenu = styled(Menu, {
  '.MuiPaper-root': {
    borderRadius: 6,
    minWidth: 200,
    marginTop: 4,
    shadow: 2,
    '&.MuiMenu-list': {
      padding: '4px 0',
    },
    '&.MuiMenuItem-root': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
});

export const StyledMenuItem = styled(MenuItem, {
  '&.MuiMenuItem-root': {
    display: 'flex',
    justifyContent: 'space-between',
    svg: {
      marginLeft: 16,
    },
  },
});
