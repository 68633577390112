import truncate from 'lodash/truncate';
import React, { useCallback, useMemo, useState } from 'react';

import { Text } from '@goodfynd/react-web.typography.text';

import { Icon } from '../Icon';
import {
  StyledFilterMenu,
  StyledFilterMenuToggle,
  StyledMenuItem,
} from './styles';

import type { FilterMenuOption, FilterMenuProps } from './types';
export default function FilterMenu({
  children,
  labelEmpty,
  onChange,
  options = [],
  selected,
  truncateLabel = true,
  ...props
}: FilterMenuProps) {
  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleClose = () => setAnchor(null);
  const handleMenuClick = (e: ClickButtonEvent) => {
    setAnchor(e.currentTarget);
  };

  const handleOptionSelect = (option: FilterMenuOption) => {
    onChange?.(option);
    handleClose();
  };

  const selectedValue = useMemo(
    () => (Array.isArray(selected) ? undefined : selected),
    [selected]
  );
  const renderLabel = useCallback(() => {
    if (props.label && !selectedValue) {
      return props.label;
    }

    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );
    if (!selectedValue) {
      return labelEmpty || selectedOption?.label;
    }

    const selectedLabel = truncateLabel
      ? truncate(selectedOption?.label, {
          length: 25,
        })
      : selectedOption?.label;

    if (props.label) {
      return `${props.label}: ${selectedLabel}`;
    }

    return selectedLabel;
  }, [labelEmpty, options, props.label, selectedValue, truncateLabel]);

  if (!children && options.length === 0) {
    return null;
  }

  return (
    <>
      <StyledFilterMenuToggle
        id="search-filter-toggle"
        aria-controls="search-filter-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disableElevation
        endIcon={<Icon name={open ? 'chevron_up' : 'chevron_down'} />}
        onClick={handleMenuClick}
        size="sm"
        display="filter"
        {...props}
      >
        <Text type="body2">{renderLabel()}</Text>
      </StyledFilterMenuToggle>

      <StyledFilterMenu
        id="search-filter-menu"
        anchorEl={anchor}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        elevation={0}
        onClose={handleClose}
        open={open}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        MenuListProps={{
          'aria-labelledby': 'search-filter-toggle',
        }}
      >
        {children ||
          options.map((option) => (
            <StyledMenuItem
              key={option.value}
              onClick={() => handleOptionSelect(option)}
              disableRipple
            >
              <span>
                {!!option.icon && <Icon name={option.icon} />}
                {option.label}
              </span>

              {selectedValue && option.value === selectedValue && (
                <Icon name="check" />
              )}
            </StyledMenuItem>
          ))}
      </StyledFilterMenu>
    </>
  );
}
